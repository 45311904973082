import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "@layout";
import SEO from "@components/seo";
import PageBreadcrumb from "../../components/pagebreadcrumb";
import VideosDataInit from "../../components/mujib-corner/videos.json";
import Videos from "../../components/mujib-corner/videosdata";
import { normalizedData } from "@utils/functions";
import { Container } from "react-bootstrap";
import axios from "axios";
import { useEffect, useState } from "react";
import Preload from "../../components/preloadPage/preload";
const MujibVideoGallery = ({ data, location, pageContext }) => {
    const [VideosData, setJsonData] = useState(VideosDataInit);
    const [preload, setPreload] = useState(true);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a756102b9fdbc3a315abe0"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
                setPreload(false);
            })
            .catch((err) => {
                console.log(err);
                setPreload(false);
            });
    }, []);

    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            {" "}
            {preload && <Preload />}
            <SEO title="Video Gallery" pathname="/" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Video Gallery"
            />
            <Container style={{ lineHeight: "150%", marginTop: "20px" }}>
                <div className="row">
                    <Videos data={VideosData} />
                </div>
            </Container>
        </Layout>
    );
};
MujibVideoGallery.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query MujibVideoGalleryQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default MujibVideoGallery;
